import Vue from 'vue';
import modals from '@/mixins/modals';
import NewStorage from '@/layouts/Stack/pages/Order/NewStorage';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  mixins: [modals, showErrorModal],
  data() {
    return {
      container: '',
      typeContainer: '1',
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
  },
  methods: {
    newStorage(storage) {
      // console.log(storage);
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewStorage',
          size: 'medium',
          component: NewStorage,
          closeOnBackdrop: false,
          title: this.$t('new'),
          props: { storage: storage },
          on: {
            change: data => {
              this.container = data.name;
              this.typeContainer = data.type;

              // console.log(data);
              // instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  // console.log(instance);
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  if (!this.project) await this.checkProject();
                  const payload = {
                    name: this.container,
                    type: this.typeContainer,
                  };

                  this.isCreatedContainer(this.container)
                    .then(async data => {
                      // console.log(data);
                      if (data === 204) {
                        that.$modals.close();
                        this.showError('Такой контейнер уже есть.');
                      }
                    })
                    .catch(e => {
                      // console.log(e);
                      if (e === 204) {
                        that.$modals.close();
                        this.showError('Такой контейнер уже есть.');
                      } else if (e === 404) {
                        this.createContainer(payload)
                          .then(async data => {
                            // console.log(data);
                            await this.fetchStoragesForNew();
                            await this.getCurrentStorage(this.container);
                            that.$modals.close();
                            this.showResModal(`Контейнер <b>${this.container}</b> успешно создан.`);
                          })
                          .catch(e => {
                            that.$modals.close();
                            // console.log(e);
                            this.showError(e);
                          });
                      }
                      // console.log(e);
                      // this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async checkProject() {
      return await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
    },
    async fetchStoragesForNew() {
      return this.$store.dispatch('moduleStack/fetchProjectStorages', this.project).then(data => {
        console.log(data);
      });
    },
    isCreatedContainer(name) {
      const params = {
        project: this.project,
        storage: name,
      };
      return this.$store.dispatch('moduleStack/isCreatedStorage', params);
    },
    async getCurrentStorage(name) {
      const paramsHead = {
        project: this.project,
        name: name,
        type: 'head',
      };
      const paramsGet = {
        project: this.project,
        name: name,
        type: 'get',
      };

      return new Promise(async resolve => {
        await this.$store.dispatch('moduleStack/fetchStorages', paramsGet).then(async data => {
          // console.log(data);
          await this.$store.dispatch('moduleStack/fetchStorages', paramsHead);
          resolve(data);
        });
      });
    },
    createContainer({ name, type }) {
      console.log(type);
      const params = {
        project: this.project,
        storage: name,
        type: type,
      };
      if (type === '2') return this.$store.dispatch('moduleStack/createStoragePublic', params);
      else return this.$store.dispatch('moduleStack/createStoragePrivate', params);
    },
  },
};
