var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack"},[(_vm.isRequest)?_c('base-loader',{staticClass:"open-ticket__loader"}):(!_vm.isRequest)?_c('page-block',{staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"stack-info__content"},[_c('page-title',{staticClass:"stack-info__title"},[_vm._v(" "+_vm._s(_vm.$t('title.server'))+" ")]),_c('div',{staticClass:"stack-info__create",class:{ visible: true }},[_c('base-button',{staticClass:"stack-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
            content: _vm.text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          },"color":_vm.isColor,"disabled":_vm.isRequest},on:{"click":function($event){_vm.isPrivateNetwork !== 0 ? _vm.newPrivateNetwork() : ''}}},[_vm._v(_vm._s(_vm.$t('newServer')))])],1)],1),(_vm.list.length === 0)?_c('base-empty',{staticClass:"cloud-info__empty",attrs:{"title":"Новая приватная сеть"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('base-button',[_vm._v(" "+_vm._s(_vm.$t('newServer'))+" ")])]},proxy:true}],null,false,121052417)}):_c('div',[_c('tariffs-table-network',{attrs:{"table-head":_vm.tableHead}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }