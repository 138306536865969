<template>
  <div class="tariffs-table">
    <DataTable
      :value="viewServersList"
      :loading="loading"
      :row-hover="true"
      :body-style="{ 'text-align': 'center' }"
      data-key="name"
      :selection.sync="selectedServers"
    >
      <template #loading> Данные загружаются, пожалуйста подождите... </template>
      <Column selection-mode="multiple" header-style="width: 3em"> </Column>
      <Column field="label" :header="$t('tableHead.name')" :styles="{ width: '40%' }">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
      </Column>
      <Column field="use" :header="$t('tableHead.ip')" :styles="{ width: '20%' }">
        <template #body="slotProps">
          <div v-if="selectedServers.find(server => server.id === slotProps.data.id)">
            <base-select
              v-model="slotProps.data.currentPort"
              select-label=""
              :max-height="120"
              :option-height="120"
              :tabindex="100"
              :hide-selected="true"
              :preselect-first="true"
              :required="true"
              :searchable="false"
              :track-by="slotProps.data.ports.addr"
              :disabled="slotProps.data.ports.length === 1"
              :custom-label="viewServers"
              :options="slotProps.data.ports"
              :open-direction="'above'"
              size="medium"
              :allow-empty="false"
              class="new-disk__services"
              @input="onChangeIp(slotProps.data.id, $event)"
            >
            </base-select>
          </div>
        </template>
      </Column>
      <Column
        field="quota"
        :header="$t('tableHead.port')"
        :styles="{ width: '20%', 'min-width': '5rem' }"
      >
        <template #body="slotProps">
          <div v-if="selectedServers.find(server => server.id === slotProps.data.id)">
            <!--              :value="slotProps.data.protocol"-->
            <base-input
              v-model="slotProps.data.protocol"
              type="number"
              :required="true"
              class="form__field--input"
              @input="onChangePort(slotProps.data.id, $event)"
            />
            <!--              @blur="onChangePortBlur(slotProps.data.id, $event)"-->
          </div>
        </template>
      </Column>
      <Column
        field="quota"
        :header="$t('tableHead.weight')"
        :styles="{ width: '20%', 'min-width': '5rem' }"
      >
        <template #body="slotProps">
          <div v-if="selectedServers.find(server => server.id === slotProps.data.id)">
            <InputNumber
              v-model="slotProps.data.weight"
              show-buttons
              mode="decimal"
              :min="1"
              :max="256"
              class="p-inputtext-lg"
              button-layout="horizontal"
              :input-style="{ 'text-align': 'center', width: '2rem' }"
              decrement-button-class="p-button-blue"
              increment-button-class="p-button-blue"
              increment-button-icon="pi pi-plus"
              decrement-button-icon="pi pi-minus"
              aria-describedby="value2-help"
              @input="onChangeWeight(slotProps.data.id, $event)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import newMember from '@/mixins/newMember';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput';

export default {
  name: 'RuleServersCreate',
  components: {
    InputNumber,
    DataTable,
    BaseSelect,
    BaseInput,
    Column,
  },
  mixins: [newMember],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    ruleid: {
      type: String,
      default: '',
    },
    subnetId: {
      type: String,
      required: true,
    },
    protocol: {
      type: Number,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      viewServersList: [],
      editingRows: [],
      loading: true,
      selectedServers: [],
      isPortUpdated: false,
      selectAll: false,
      totalRecords: 0,
      isLoading: true,
      stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    members() {
      return this.$store.getters['moduleStack/getCurrentMembers'](this.ruleid);
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.dataset.slice(start, end);
    },
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    networkId() {
      return this.$store.state.moduleStack.subnets.find(x => x.id === this.subnetId).network_id;
    },
    ports() {
      let ports = [];
      const newPorts = [];
      ports = this.$store.state.moduleStack.ports
        .filter(x => x.device_owner === 'compute:nova')
        .filter(x => !x.name.startsWith('octavia-'))
        .filter(el => {
          return this.modifyServer.some(f => {
            return f.id === el.device_id;
          });
        })
        .map(x => x.getPort(this.subnetId));

      ports.forEach(port => {
        port.port.forEach(el => {
          if (el.subnet_id === this.subnetId) {
            newPorts.push({
              network: port.network,
              server: port.server,
              subnet: el.subnet_id,
              port: el.ip_address,
            });
          }
        });
      });

      return newPorts;
    },
    showPagination() {
      return this.members && this.members.length > this.stepOptions[0];
    },
    modifyServer() {
      return this.servers.map(x => x.server);
    },
  },
  watch: {
    servers: {
      handler(event) {
        this.isLoading = false;
        event.server;
      },
      immediate: true,
    },
    viewServersList: {
      handler(event) {
        if (event && this.selectedServers.length > 0) {
          this.selectedServers = this.selectedServers.map(obj => {
            if (obj.protocol) {
              return { ...obj, protocol: this.protocol };
            }
            return obj;
          });
        }
        if (!event && this.selectedServers.length) {
          this.init();
        }
      },
      deep: true,
    },
    selectedServers: {
      handler(event) {
        // console.log('selectedServersUpdate', event);
        if (event && !!event.length) {
          const members = [];
          // console.log(this.viewServersList);
          event
            .map(x => x.id)
            .forEach(id => {
              // console.log(id);
              const address = this.viewServersList.find(x => x.id === id).currentPort.addr;
              // console.log(address);
              const filtredPort = this.ports
                .filter(port => port.server === id)
                .map(port => port.port)
                .flat()
                .find(ip => ip.ip_address === address);
              // console.log(filtredPort);
              const port = this.viewServersList.find(x => x.id === id).protocol;
              const name = this.viewServersList.find(x => x.id === id).name;
              const weight = this.viewServersList.find(x => x.id === id).weight;
              const currentMember = {
                // subnet_id: filtredPort.subnet_id,
                name: name,
                address: address,
                protocol_port: +port,
              };
              if (weight > 1) currentMember.weight = weight;
              // console.log(currentMember.subnet_id, 'sub', this.subnetId);
              // if (currentMember.subnet_id === this.subnetId) members.push(currentMember);
              members.push(currentMember);
              // console.log(members);
            });
          this.$emit('add-servers', { id: this.id, members: members });
        } else {
          this.$emit('add-servers', { id: this.id, members: event });
        }
      },
      immediate: false,
      // flush: 'post',
      deep: true,
    },
    subnetId: {
      handler(event) {
        if (event) {
          this.init();
        }
      },
      immediate: true,
    },
    loading: {
      handler: function (event) {},
      immediate: true,
    },
    protocol: {
      handler(event) {
        if (event) {
          this.updateProtocol(event);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const viewServersList = this.$store.state.moduleStack.servers.map(x => x.server);
      const servers = [];
      viewServersList.forEach(server => {
        const listPorts = [];
        server.ports.forEach(port => {
          this.ports.forEach(el => {
            if (el.port === port.addr) {
              listPorts.push(port);
            }
          });
        });
        servers.push({
          currentPort: listPorts[0],
          flavor: server.flavor,
          id: server.id,
          name: server.name,
          protocol: server.protocol,
          ports: listPorts,
          weight: server.weight,
        });
      });
      servers.filter(x => !!x.currentPort);
      this.viewServersList = servers
        .filter(x => !!x.currentPort)
        .map(obj => {
          if (obj.protocol) {
            return { ...obj, protocol: this.protocol };
          }
          return obj;
        });
      this.loading = false;
    },
    updateProtocol(protocol) {
      this.viewServersList = this.viewServersList.map(obj => {
        if (obj.protocol) {
          return { ...obj, protocol: protocol };
        }
        return obj;
      });
    },
    onStepChange(value) {
      this.pagin.step = value;
    },
    viewServers({ addr }) {
      return addr;
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;

      if (selectAll) {
        this.selectAll = true;
        this.selectedServers = this.viewServersList;
      } else {
        this.selectAll = false;
        this.selectedServers = [];
      }
    },
    onRowSelect(event) {},
    onRowUnselect(event) {},
    onPageChange(value) {
      this.pagin.start = this.pagin.step * (value - 1);
    },
    addMember() {
      this.newMember();
    },
    onChangePort(item, event) {
      this.viewServersList.find(x => x.id === item).protocol = +event;
    },
    onChangeWeight(item, event) {
      this.selectedServers.find(x => x.id === item).weight = +event;
    },
    onChangeIp(item, event) {
      this.viewServersList.find(x => x.id === item).currentPort = Object.assign({}, event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "weight": "Вес",
      "port": "Порт",
      "config": "Конфигурация",
      "status": "Статус",
      "ip": "IP",
      "menu": "Меню"
    },
    "add" : "Добавить сервер",
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;
  margin-top: 2rem;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
