<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="network"
        :placeholder="$t('network.placeholder')"
        use-reactive-validation
        hint="Имя сети должно быть не более 255 символов."
        :custom-error-messages="
          customValidationServerMsgs
            ? customValidationServerMsgs
            : validateLength(network, 1, 255)
            ? network
            : null
        "
        :pattern="validateLength(network, 1, 255) ? network : 'tooMach'"
        :label="$t('network.label')"
        class="form__field--input"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <base-checkbox
        :key="formData.checkbox.key"
        :disabled="isPrivateNetworkSubnet === 0"
        :name="formData.checkbox.name"
        :value="formData.checkbox.value"
        :true-value="formData.checkbox.trueValue"
        :false-value="formData.checkbox.falseValue"
        class="security-access-limit__field"
        :class="{ ['security-access-limit__field--full']: formData.checkbox.key === 'subnet' }"
        @change="onCheck('checkbox', $event)"
      >
        <label class="typo__label standart-title paragraf">{{ formData.checkbox.label }}</label>
      </base-checkbox>
      <p v-if="isPrivateNetworkSubnet === 0" v-html="text"></p>
    </div>
    <div v-if="formData.checkbox.value === 'on' && isPrivateNetworkSubnet !== 0">
      <div class="l-col">
        <base-input
          :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
          use-reactive-validation
          :pattern="patternIP"
          :label="$t('subnetwork.label')"
          :value="subnet"
          class="form__field--input"
          @input="onChange('subnet', $event)"
        >
        </base-input>
      </div>
      <div class="l-col">
        <base-checkbox
          :key="formData.gateway.key"
          :name="formData.gateway.name"
          :value="formData.gateway.value"
          :true-value="formData.gateway.trueValue"
          :false-value="formData.gateway.falseValue"
          class="security-access-limit__field paragraf"
          :class="{ ['security-access-limit__field--full']: formData.gateway.key === 'gateway' }"
          @change="onCheck('gateway', $event)"
        >
          <label class="typo__label standart-title paragraf">{{ formData.gateway.label }}</label>
        </base-checkbox>
      </div>
      <div v-if="formData.gateway.value === 'off'" class="l-col">
        <label class="typo__label standart-title paragraf">{{ $t('gateway.label') }}</label>
        <base-input
          use-reactive-validation
          :readonly="true"
          :value="gateway ? gateway : computeGateway"
          class="paragraf-up"
          @input="onChange('gateway', $event)"
        >
        </base-input>
      </div>
    </div>
    <div class="l-col dns">
      <base-checkbox
        :key="formData.dhcp.key"
        :hint="hintDns"
        :name="formData.dhcp.name"
        :value="formData.dhcp.value"
        :true-value="formData.dhcp.trueValue"
        :false-value="formData.dhcp.falseValue"
        :class="{ ['security-access-limit__field--full']: formData.dhcp.key === 'dhcp' }"
        @change="onCheck('dhcp', $event)"
      >
        <label class="typo__label standart-title paragraf">{{ formData.dhcp.label }}</label>
      </base-checkbox>
      <plain-button
        v-if="defaultDnsNames !== dnsNames"
        color="primary"
        class="tariffs-table__prolong"
        @click="getDnsDefault"
      >
        {{ $t('dns.restore') }}
      </plain-button>
    </div>
    <div v-if="formData.dhcp.value === 'on'" class="l-col">
      <base-input
        v-model="dnsNames"
        type="textarea"
        size="medium"
        :placeholder="$t('dns.placeholder')"
        @input="onChange('dns', $event)"
      />
    </div>
    <!--    </div>-->
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'NewPrivateNetwork',
  components: { BaseInput, BaseCheckbox },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // customValidationMsgs: {
      //   confirm: { patternMismatch: this.$t('error') },
      // },
      value: '',
      lengthName: 255,
      dnsNames: '',
      defaultDnsNames: '',
      network: '',
      subnet: '192.168.0.0/24',
      subnetAddr: '',
      mask: '',
      gateway: '',
      lastIpSection: '',
      formData: {
        checkbox: {
          value: 'on',
          key: 'subnet',
          label: 'Создать подсеть',
          trueValue: 'on',
          falseValue: 'off',
        },
        dhcp: {
          value: 'on',
          key: 'dhcp',
          label: 'Включить DHCP',
          trueValue: 'on',
          falseValue: 'off',
        },
        gateway: {
          value: 'off',
          key: 'gateway',
          label: 'Запретить шлюз',
          trueValue: 'on',
          falseValue: 'off',
        },
      },
    };
  },
  computed: {
    isValid() {
      return this.validateLength(this.network, 1, this.lengthName);
    },
    hintDns() {
      return `Корректная работа сети гарантируется только при использовании наших DNS-серверов:<br />
${this.defaultDnsNames.split('\n')[0]}<br />
${this.defaultDnsNames.split('\n')[1]}<br />
`;
    },
    customValidationMsgs() {
      return {
        confirm: {
          patternMismatch: this.validateLength(this.network, 1, this.lengthName)
            ? this.$t('error.created')
            : this.$t('error.new'),
        },
      };
    },
    customValidationServerMsgs() {
      return {
        confirm: {
          patternMismatch: this.$t('error.new'),
        },
      };
    },
    // isValue() {
    //   return this.isPrivateNetworkSubnet === 0 ? 'off' : this.formData.checkbox.value;
    // },
    // isSubnet() {
    //   return this.isPrivateNetworkSubnet === 0 ? '' : this.subnet;
    // },
    // isGateways() {
    //   return this.isPrivateNetworkSubnet === 0
    //     ? ''
    //     : this.gateway
    //     ? this.gateway
    //     : this.computeGateway;
    // },
    // isDhcp() {
    //   return this.isPrivateNetworkSubnet === 0 ? 'off' : this.formData.dhcp.value;
    // },
    text() {
      return `Вы достигли квоты по количеству подсетей.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
        this.bmId
      }/quotas">Квоты</a></strong> для увеличения. `;
    },
    isPrivateNetworkSubnet() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.subnet
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.subnet.limit -
          this.$store.state.moduleStack.quotasNetwork.subnet.used
        );
      } else return 0;
    },
    computeGateway() {
      return !this.isGateway
        ? null
        : `${this.getMask(this.subnet)}${this.getLastIpSection(this.subnet)}`;
    },
    sizeSubnet() {
      return +this.subnet.split('/')[1] || null;
    },
    isGateway() {
      return this.formData.gateway.value !== 'on';
    },
    patternIP() {
      return this.isIPv4(this.mask) &&
        this.lastIpSection === 0 &&
        this.subnetAddr > 23 &&
        this.subnetAddr < 30
        ? `${this.mask}${this.lastIpSection}/${this.subnetAddr}`
        : 'null';
    },
  },
  watch: {
    isValid: {
      handler: function (event) {
        // console.log(event);
        if (!event) this.$emit('notready');
      },
    },
    computeGateway: function (event) {
      this.gateway = '';
      if (!event) return null;
      return this.getLastIpSection(event) === 2
        ? (this.gateway = this.computeGateway)
        : (this.gateway = 'не верный формат подсети');
    },
  },
  mounted() {
    this.isCheck();
    this.getDnsDefault();
    this.$emit('notready');
  },
  methods: {
    getDnsDefault() {
      this.dnsNames = this.$store.state.moduleStack.dnsDefault.join('\n');
      this.defaultDnsNames = this.dnsNames;
    },
    isCheck() {
      if (this.isPrivateNetworkSubnet === 0) {
        this.network = '';
        this.subnet = '';
        this.gateway = null;
        this.allocation_pools = null;
        this.formData.dhcp.value = 'off';
        this.formData.checkbox.value = 'off';
      }
    },
    onCheck(name, val) {
      this.formData[name].value = val;
      return this.$emit('change', {
        net: this.network,
        subnet: this.subnet,
        gateway: this.formData.gateway.value === 'on' ? null : this.computeGateway,
        allocation_pools: this.gateway ? this.allocationPools(this.sizeSubnet) : null,
        dhcp: this.formData.dhcp.value,
        dns_nameservers:
          this.formData.dhcp.value === 'on'
            ? this.dnsNames
              ? this.dnsNames
              : this.defaultDnsNames
            : [],

        enabled: this.formData.checkbox.value,
      });
    },
    validateLength(input, min, max) {
      let num = input.length;
      // console.log(num);
      // console.log(num >= min && num <= max);
      return num >= min && num <= max;
    },
    allocationPools(ip) {
      let pools = [];
      if (ip === 24) {
        pools.push({
          end: `${this.getMask(this.subnet)}254`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 25) {
        pools.push({
          end: `${this.getMask(this.subnet)}126`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 26) {
        pools.push({
          end: `${this.getMask(this.subnet)}62`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 27) {
        pools.push({
          end: `${this.getMask(this.subnet)}30`,
          start: `${this.getMask(this.subnet)}2`,
        });
      } else if (ip === 28) {
        pools.push({
          end: `${this.getMask(this.subnet)}14`,
          start: `${this.getMask(this.subnet)}2`,
        });
      } else if (ip === 29) {
        pools.push({
          end: `${this.getMask(this.subnet)}6`,
          start: `${this.getMask(this.subnet)}2`,
        });
      }

      return pools;
      // return { start: `${this.getMask(this.subnet)}2`, end: `${this.getMask(this.subnet)}254` };
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    getLastIpSection(ip) {
      this.lastIpSection = +ip.split('/').at(0).split('.').at(-1);
      this.subnetAddr = +ip.split('/').at(-1);
      return this.lastIpSection + 1;
    },
    getMask(ip) {
      this.mask = ip.slice(0, ip.lastIndexOf('.') + 1);
      return this.mask;
    },
    onChange(name, event) {
      // console.log(name, event);
      if (!this.isValid) return this.$emit('notready');
      if (this[name] === 'subnet') {
        // console.log('subnet');
      }
      this[name] = event;
      if (this.network && this.formData.checkbox.value === 'off') {
        return this.$emit('change', {
          net: this.network,
          enabled: this.formData.checkbox.value,
        });
      } else if (this.network && this.subnet && this.computeGateway) {
        return this.$emit('change', {
          net: this.network,
          subnet: this.subnet,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
          gateway: this.formData.gateway.value === 'on' ? null : this.computeGateway,
          // gateway: this.computeGateway,

          dhcp: this.formData.dhcp.value,
          enabled: this.formData.checkbox.value,
        });
      } else if (this.network && this.subnet && !this.computeGateway) {
        return this.$emit('change', {
          net: this.network,
          subnet: this.subnet,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
          // gateway: null,
          gateway: this.formData.gateway.value === 'on' ? null : this.computeGateway,
          allocation_pools: this.allocationPools(this.sizeSubnet),
          dhcp: this.formData.dhcp.value,
          enabled: this.formData.checkbox.value,
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "dns": {
    "restore": "Вернуть DNS по умолчанию",
      "placeholder": "DNS адреса"
    },
    "subnetwork": {
      "label": "CIDR подсети:",
      "placeholder": "",
      "gateway": "Шлюз",
      "dhcp": "Состояние"
    },
    "error": {
      "created":"Сеть с таким именем уже существует.",
      "new":"Длина имени сети не должна превышать 255 символов."
    },
    "network": {
      "label": "Имя приватной сети:",
      "placeholder": "Имя сети"
    },
    "gateway": {
      "label": "Шлюз:",
      "placeholder": "Имя сети"
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер",
    "quotaNetwork": "Вы достигли квоты по количеству подсетей, обратитесь в поддержку для изменения квоты"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.paragraf {
  margin-bottom: 10px;
  &-up{
  margin-top: 10px;

  }
}
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;

  &-input {
    padding: 1.5rem 1.5rem;
}
  }
}
</style>
