<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <page-block class="vps-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ $t(`${item.name}`) }}
              </div>
              <div class="addons__value standart-text">
                {{ item.value }}
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import { format } from 'date-fns';
export default {
  name: 'PoolView',
  components: {
    MainCard,
  },
  mixins: [providerChange],
  props: {
    rule: {
      type: Object,
      default: () => {},
      // validator: obj => obj instanceof OPTariff,
    },
    float: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return this.$store.state.moduleStack.flavorprofiles;
    },
    addons() {
      const specs = this.rule;
      let list = [];
      if (specs && specs.name) list.push({ name: 'name', value: specs.name });
      if (specs && specs.id) list.push({ name: 'ID', value: specs.id.toString() });
      if (specs && specs.created_at)
        list.push({ name: 'date', value: format(specs.created_at, 'dd.MM.yyyy HH:mm:ss') });
      return list;
    },
  },
  mounted() {},
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "name": "Имя",
      "date": "Дата создания"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
