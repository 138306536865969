<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <base-button class="stack-info__create-btn" :to="{ name: 'NewBalancer' }">
            {{ $t('newBalancer') }}
          </base-button>
        </div>
      </div>
      <base-empty v-if="list.length === 0" title="Новая приватная сеть" class="cloud-info__empty">
        <base-button slot="link">
          {{ $t('newBalancer') }}
        </base-button>
      </base-empty>
      <tariffs-table-balancer :table-head="tableHead" />
    </page-block>
  </div>
</template>

<script>
import TariffsTableBalancer from '../../Main/components/TariffsTableBalancer';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newPrivateNetwork from '@/mixins/newPrivateNetwork';
export default {
  name: 'ViewBalancer',
  components: {
    TariffsTableBalancer,
    BaseEmpty,
  },
  mixins: [storeMixin, newPrivateNetwork],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '400px',
            maxWidth: '400px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'tariff',
          label: this.$t('tableHead.tariff'),
          style: {
            width: '130px',
            maxWidth: '130px',
            textAlign: 'center',
          },
        },
        {
          key: 'rule',
          label: this.$t('tableHead.rule'),
          style: {
            width: '130px',
            maxWidth: '130px',
            textAlign: 'center',
          },
        },

        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '50px',
            // textAlign: 'center',
          },
          sort: {
            prop: 'operating_status',
            order: 'asc',
          },
        },
        // {
        //   key: 'state',
        //   label: this.$t('tableHead.state'),
        //   style: {
        //     width: '48px',
        //   },
        //   sort: {
        //     prop: 'provisioning_status',
        //     order: 'asc',
        //   },
        // },
        {
          key: 'conditions',
          // label: this.$t('tableHead.conditions'),
          style: {
            width: '70px',
            maxWidth: '70px',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
            textAlign: 'center',
          },
        },
      ],
      isRequest: true,
      diskId: '',
    };
  },
  computed: {},
  mounted() {
    this.getOpenStackApiKey().then(() => {
      this.newFetch();
    });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Балансировщики"
    },
    "tableHead": {
      "name": "Имя",
      "type": "Тип",
      "network": "Сеть",
      "tariff": "Тариф",
      "shared": "Публичная",
      "config": "Конфигурация",
      "conditions": "",
      "status": "Статус",
      "state": "Состояние",
      "rule": "Правила",
      "menu": "Меню"
    },
    "sure": {
      "confirm": "Создать"
    },
    "newBalancer": "Новый балансировщик",
    "quotaNetwork": "Вы достигли квоты по количеству приватных сетей, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
