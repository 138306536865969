export function objectSizeParser(item) {
  const size = item / Math.pow(1024, 1);
  switch (true) {
    case size < 1:
      return `${item} Б`;
    case size < 1024:
      return `${(item / Math.pow(1024, 1)).toFixed(2)} KБ`;
    case size < 1024 ** 2:
      return `${(item / Math.pow(1024, 2)).toFixed(2)} МБ`;
    case size < 1024 ** 3:
      return `${(item / Math.pow(1024, 3)).toFixed(2)} ГБ`;
    case size < 1024 ** 4:
      return `${(item / Math.pow(1024, 4)).toFixed(2)} TБ`;
    default:
      return NaN;
  }
}
