import RenameBalancer from '@/layouts/Stack/components/RenameBalancer';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    renameBalancer: function (instance, item) {
      const that = this;
      return new Promise(() => {
        const selfName = 'RenameBalancer';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: RenameBalancer,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              instance = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('modal.sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.renameNameBalancer(instance, item)
                    .then(data => {
                      console.log(instance, data);
                      this.$modals.close({ name: selfName });
                      if (data.name === instance) {
                        this.showResModal('Имя балансировщика успешно изменено.');
                      }
                    })
                    .catch(e => this.showError(e));
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    renameNameBalancer(name, item) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateBalancer', {
        loadbalancer: {
          name: name,
        },
        id: item.id,
        item: 'name',
      });
    },
  },
};
