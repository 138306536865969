export default {
  data() {
    return {
      timerCnt: 1,
      timers: {},
      timerId: null,
      startCyclingBalancer: false,
      startCyclingPool: false,
    };
  },
  methods: {
    async updateBalancerStatus(id) {
      // console.log(this.timers);
      if (this.timers && this.timers[id]) {
        // console.log(this.timers[id]);
        // console.log(this.timerId);
      } else {
        this.timers = {};
        this.timers[id] = 3;
        this.timerId = setTimeout(() => {
          this.$store
            .dispatch('moduleStack/checkBalancerStatus', {
              id: id,
            })
            .then(async data => {
              // console.log(data.data.provisioning_status);
              this.startCyclingBalancer = true;
              // console.log(this.timers[id], id);
              if (data.data.provisioning_status.includes('PENDING_')) {
                // console.log(this.timers[id], id, '**********************');
                // if (this.timerCnt < 5) this.timerCnt += 1;
                if (this.timers[id] < 5) this.timers[id] += 1;
                await this.updateBalancerStatus(id);
              } else {
                // await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
                this.timerCnt = 0;
                delete this.timers[id];
                this.startCyclingBalancer = false;
                clearTimeout(this.timerId);
                setTimeout(() => {
                  this.$store
                    .dispatch('moduleStack/checkBalancerStatus', {
                      id: id,
                    })
                    .then(() => {
                      this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
                      this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
                    });
                }, 7000);
                return 'finish';
              }
            })
            .catch(e => {
              console.log(e);
              this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
            });
        }, 1000 * this.timers[id]);
        this.timerId = null;
      }
      // console.log(this.timers, this.timers[id], this.timerId);
      // if (!this.timerId)
      // console.log(this.timers[id] === undefined);
      // console.log('id' in this.timers);

      // if (!this.timers[id])
      //   this.timerId = setTimeout(() => {
      //     this.timers = {};
      //     this.timers[id] = 1;
      //     this.$store
      //       .dispatch('moduleStack/checkBalancerStatus', {
      //         id: id,
      //       })
      //       .then(async data => {
      //         console.log(data.data.provisioning_status);
      //         this.startCyclingBalancer = true;
      //         console.log(this.timers[id], id);
      //         if (data.data.provisioning_status.includes('PENDING_')) {
      //           console.log(this.timers[id], id, '**********************');
      //           // if (this.timerCnt < 5) this.timerCnt += 1;
      //           if (this.timers[id] < 5) this.timers[id] += 1;
      //           await this.updateBalancerStatus(id);
      //         } else {
      //           // await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
      //           this.timerCnt = 0;
      //           delete this.timers[id];
      //           this.startCyclingBalancer = false;
      //           clearTimeout(this.timerId);
      //           setTimeout(() => {
      //             this.$store
      //               .dispatch('moduleStack/checkBalancerStatus', {
      //                 id: id,
      //               })
      //               .then(() => {
      //                 this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
      //                 this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
      //               });
      //           }, 7000);
      //           return 'finish';
      //         }
      //       })
      //       .catch(e => {
      //         console.log(e);
      //         this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
      //       });
      //   }, 1000 * this.timers[id]);
      // console.log(this.timerId);
      // console.log(this.timers[id]);
    },
    async updatePoolStatus(id) {
      // console.log('updatePoolStatus ID:', id);
      if (this.timers && this.timers[id]) {
      } else {
        this.timers = {};
        this.timers[id] = 1;
      }
      this.timerPoolId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkPoolStatus', {
            id: id,
          })
          .then(async data => {
            if (data.data.provisioning_status.includes('PENDING_')) {
              // this.startCyclingPool = true;
              // if (this.timerCnt < 5) this.timerCnt += 1;
              if (this.timers[id] < 5) this.timers[id] += 1;
              await this.updatePoolStatus(id);
            } else {
              this.timerCnt = 0;
              delete this.timers[id];
              // this.startCyclingPool = false;
              clearTimeout(this.timerId);
              setTimeout(() => {
                this.$store;
                this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
              }, 10000);
            }
          })
          .catch(e => {
            console.log(e);
            this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
          });
      }, 1000 * this.timers[id]);
      // console.log(this.timers[id]);
    },
  },
};
