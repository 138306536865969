<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariff-table-item__server">
        <router-link
          v-if="!item.provisioning_status.key.toLowerCase().includes('pending')"
          :to="{ name: 'Balancer', params: { balancerid: item.id } }"
          class="tariffs-table-item__link medium-text"
        >
          {{ item.name }}
        </router-link>
        <div
          v-if="item.provisioning_status.key.toLowerCase().includes('pending')"
          class="tariffs-table-item__specs medium-text"
        >
          {{ item.name }}
        </div>
        <div class="tariffs-table-item__specs note-text">
          {{
            `Плавающий IP: ${
              !!floatingIpsServer ? floatingIpsServer.floating_ip_address : 'подключить'
            }`
          }}
        </div>
        <div class="tariffs-table-item__specs note-text">
          {{ item.description }}
        </div>
      </div>
    </template>
    <template #tariff>
      <div class="tariff-table-item__server">
        <div class="tariffs-table-item__specs medium-text">
          <span>{{ item.flavor_id === type.advanced ? 'Расширенный' : 'Базовый' }}</span>
        </div>
      </div>
    </template>

    <template #rule>
      <!--      <div v-for="rule in item.pools" :key="rule.id" class="tariffs-table-item__sub">-->
      <div v-for="rule in pools" :key="rule.id" class="tariffs-table-item__sub">
        <router-link
          :to="{ name: 'Rule', params: { balancerid: item.id, ruleid: rule.id } }"
          class="tariffs-table-item__link medium-text"
        >
          {{ viewRules(rule.id) }}
        </router-link>
      </div>
    </template>
    <template #conditions>
      <tumbler
        :value="item.admin_state_up"
        :disabled="item.provisioning_status.key.toLowerCase().includes('pending')"
        class="theme-switcher__tumbler"
        @change="setState"
      />
      <main-label
        :color="
          item.provisioning_status.key.toLowerCase().includes('pending')
            ? 'warning'
            : item.admin_state_up === true
            ? 'success'
            : 'error'
        "
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{
          item.provisioning_status.key.toLowerCase().includes('pending')
            ? $t(`balancer.${item.provisioning_status.key.toUpperCase()}`)
            : item.admin_state_up === true
            ? $t('on')
            : $t('off')
        }}
      </main-label>
    </template>
    <template #status>
      <main-label
        :color="item.operating_status.color"
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{ $t(`balancer.${item.operating_status.key.toUpperCase()}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <balancer-context-menu
          slot="popover"
          :tariff="item"
          :floating-ip="floatingIpsServer ? floatingIpsServer.floating_ip_address : ''"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import BalancerContextMenu from './BalancerContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
import Tumbler from '@/components/BaseTumbler/Tumbler.vue';
import update from '@/layouts/Stack/mixins/update';
export default {
  name: 'TariffsTableBalancerRow',
  components: {
    BaseTableRow,
    MainLabel,
    Tumbler,
    BalancerContextMenu,
  },
  mixins: [popoverFix, update],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timerCnt: 0,
      timers: null,
      // startCycling: false,
    };
  },
  computed: {
    specs() {
      return `${this.$t('specs.id')}${this.item.id} ${this.$t('specs.ip')}${this.item.vip_address}`;
    },
    pools() {
      const allPools = this.$store.state.moduleStack.pools;
      const poolIds = this.item.pools.map(x => x.id);
      // console.log(poolIds);
      const filtredPools = allPools
        .filter(x => poolIds.includes(x.id))
        .sort((x, y) => x.name - y.name);

      // return this.$store.state.moduleStack.pools;
      // console.log(filtredPools.map(x => x.name));
      return filtredPools;
    },
    floatingIpsServer() {
      return this.$store.state.moduleStack.floatingips.find(
        x => x.port_id === this.item.vip_port_id
      );
    },
    type() {
      return this.$store.state.moduleStack.flavorprofiles;
    },
    listener() {
      return this.$store.state.moduleStack.listeners.find(x => x.id === this.item.listeners[0].id);
    },
    isSsl() {
      return (
        !!this.listener &&
        !!this.listener.default_tls_container_ref &&
        !!this.listener.default_tls_container_ref.length
      );
    },
  },
  watch: {
    item: {
      handler: function (event) {
        // console.log(this.startCycling);
        // console.log('watchBalancerStatus', event.provisioning_status.key);
        if (
          event &&
          event.provisioning_status &&
          !this.startCycling &&
          event.provisioning_status.key.toLowerCase().includes('pending_')
        ) {
          this.updateBalancerStatus(this.item.id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    printParam(param, payload) {
      return param.toString(payload);
    },
    viewRules(id) {
      if (this.pools.find(i => i.id === id) && this.pools.find(i => i.id === id).listeners) {
        const listenerId = this.pools.find(i => i.id === id).listeners[0].id;
        const listener = this.$store.state.moduleStack.listeners.find(l => l.id === listenerId);
        // const sslEnabled =
        //   listener &&
        //   listener.default_tls_container_ref &&
        //   listener.default_tls_container_ref.length;

        return this.pools.find(i => i.id === id)
          ? `${this.pools.find(i => i.id === id).name}` // ${sslEnabled ? 'SSL' : ''}`
          : '';
      } else return '';
    },
    subnetName(id) {
      return this.$store.state.moduleStack.subnets.find(x => x.id === id)
        ? this.$store.state.moduleStack.subnets.find(x => x.id === id).name
        : '';
    },
    networkName(id) {
      return this.$store.state.moduleStack.networks.find(x => x.id === id)
        ? this.$store.state.moduleStack.networks.find(x => x.id === id).name
        : '';
    },
    setState(value) {
      // console.log(value);
      const payload = {
        loadbalancer: {
          admin_state_up: value,
        },
        id: this.item.id,
        item: 'admin_state_up',
      };
      this.$store.dispatch('moduleStack/updateBalancer', payload).then(data => {
        // console.log(data);
        this.updateBalancerStatus(this.item.id);
        // }
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "on": "Включён",
    "off": "Выключен",
    "specs": {
      "id": "ID:",
      "ip": "IP:",
      "disc": "Disc"
    },
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
