import DeleteStorage from '@/layouts/Stack/components/DeleteStorage';
import Vue from 'vue';
import modals from '@/mixins/modals';
export default {
  mixins: [modals],
  data() {
    return {
      container: '',
      objectsContainer: '',
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    // objects() {
    //   return this.$store.state.moduleStack.objects[this.storage.name].map(x => x.name);
    // },
  },
  methods: {
    deleteStorage(storage) {
      console.log(storage);
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'DeleteStorage',
          size: 'medium',
          component: DeleteStorage,
          closeOnBackdrop: false,
          title: this.$t('delete'),
          props: { storage: storage },
          on: {
            change: data => {
              this.container = data.storage;
              this.objectsContainer = data.object;
              // console.log(data);
              // instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            submit: () => {
              Vue.set(this.modal.footer.confirm.on.click());
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: () => {
                  // console.log(instance);
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  this.deleteContainer(this.container)
                    .then(async data => {
                      // console.log(data);
                      await this.fetchStorages();
                      that.$modals.close();
                      this.showResModal('Контейнер успешно удалён.');
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async fetchStorages() {
      return this.$store.dispatch('moduleStack/fetchProjectStorages', this.project).then(data => {
        // console.log(data);
      });
    },
    deleteContainer(name) {
      let payloadForDelete;
      payloadForDelete = this.objectsContainer.map(item => `/${name}/${item}`);
      payloadForDelete = payloadForDelete.join('\n');

      const params = {
        project: this.project,
        forDelete: `${payloadForDelete}\n/${name}`,
      };
      return this.$store.dispatch('moduleStack/deleteStorage', params);
    },
  },
};
