<template>
  <div class="tariffs-table-tag">
    <div class="tariffs-table-tag__tags">
      <div v-for="tag in tags" :key="tag" class="tariffs-table__tags-item">
        <plain-button class="tariffs-table__prolong" @click="clickTag(tag)">
          <main-label
            :color="selectedTags.includes(tag) ? 'primary' : 'note'"
            class="tariff-card__label"
          >
            {{ tag }}
          </main-label>
        </plain-button>
      </div>
      <div v-if="!!selectedTags.length" class="tariffs-table__tags-reset">
        <plain-button color="primary" @click="resetTag()"> {{ $t('reset') }} </plain-button>
      </div>
    </div>
    <div class="tariffs-table-tag__search">
      <span class="p-float-label p-input-icon-left search">
        <i class="pi pi-search" />
        <InputText id="search" v-model="searchList" type="text" class="search" />
        <label for="search">Поиск</label>
      </span>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import MainLabel from '@/components/Labels/MainLabel';
import InputText from 'primevue/inputtext';
export default {
  name: 'TagsFilter',
  components: { MainLabel, InputText },
  mixins: [setFocus],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: () => '',
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    activeSearch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchList: '',
    };
  },
  computed: {
    // tagsW() {
    //   return this.$store.getters['moduleStack/getTags'];
    // },
  },
  watch: {
    searchList: {
      handler: function (event) {
        if (event) this.$emit('search-server', event);
        else this.$emit('search-server', '');
      },
    },
  },
  mounted() {
    this.searchList = this.search;
  },

  methods: {
    clickTag(event) {
      this.$emit('click-tag', event);
    },
    resetTag() {
      this.$emit('reset');
      // this.selectedTags = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "reset": "Сбросить все теги"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-tag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &__tags {
    margin: 1.25rem 0;
    display flex
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin: 0.5rem 0 0.5rem 0.5rem

    }
    &-reset {
      margin: 0.5rem 0 0.5rem 0.5rem
    }
  }

  &__search {
    min-width: 25%
  }

}
  .search {
    width: 100%
  }
</style>
