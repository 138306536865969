<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-balancer-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="balancers.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableBalancerRow from './TariffsTableBalancerRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableBalancer',
  components: {
    BaseTable,
    TariffsTableBalancerRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    type: {
      type: Boolean,
      default: false,
    },
    tableHead: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stepOptions: [10, 25, 50, 100],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    balancers() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_BALANCERS'];
    },
    sort() {
      return this.$store.state.moduleStack.sort.balancer;
    },
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.balancers.slice(start, end);
    },
    showPagination() {
      return this.balancers.length > this.stepOptions[0];
    },
  },
  watch: {
    sort: {
      handler(val, old) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onStepChange(value) {
      this.pagin.step = value;
    },
    onPageChange(value) {
      this.pagin.start = this.pagin.step * (value - 1);
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_BALANCER_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "state": "Состояние",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
