export default {
  data() {
    return {
      // id: '',
    };
  },
  computed: {
    networksPrivate() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === false)
        .map(x => x.id);
    },
    networksPublic() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === true)
        .map(x => x.id);
    },
    subnetPublic() {
      return this.$store.state.moduleStack.subnets.filter(x =>
        this.networksPublic.includes(x.network_id)
      );
    },
    networksPublicName() {
      // return this.$store.state.moduleStack.networks.find(x => x.id === this.networksPublic[0]).name;
      return this.$store.state.moduleStack.subnets.find(x => x.id === this.subnetPublic[0]).name;
      // .filter(x => x.shared === true)
      // .map(x => x.name);
    },
    subnet() {
      const filtredSub = [];
      const sub = this.$store.state.moduleStack.subnets;
      this.networksPrivate.forEach(network => {
        filtredSub.push(sub.filter(subnet => subnet.network_id === network));
      });
      this.networksPublic.forEach(network => {
        filtredSub.push(sub.filter(subnet => subnet.network_id === network));
      });
      return filtredSub.flat().map(x => x.id);
    },
    subnetPrivate() {
      const filtredSub = [];
      const sub = this.$store.state.moduleStack.subnets;
      this.networksPrivate.forEach(network => {
        filtredSub.push(sub.filter(subnet => subnet.network_id === network));
      });
      return filtredSub.flat().map(x => x.id);
    },
    networksWithRouter() {
      const listNetwork = [];
      this.networksPrivate.forEach(net => {
        const networksPort = this.$store.state.moduleStack.ports
          .filter(port => port.network_id === net)
          .filter(x => x.device_owner === 'network:router_interface');
        // .find(x => x.device_owner === 'network:router_interface');
        networksPort.length > 0 ? listNetwork.push(networksPort[0].network_id) : null;
      });
      // console.log(listNetwork);
      return listNetwork;
    },
    isSubnetWithRouter() {
      const filtredSub = [];
      const sub = this.$store.state.moduleStack.subnets;
      // this.networksPrivate
      this.networksWithRouter.forEach(network => {
        filtredSub.push(sub.filter(subnet => subnet.network_id === network));
      });
      return filtredSub.flat().map(x => x.id);
    },
    filtredSub() {
      return this.subnetPrivate.filter(x => !this.usedSubnets.includes(x));
    },
    availablePorts() {
      const arr = [];
      const subnets = this.subnet;
      subnets.forEach(net => {
        arr.push(this.getAvailablePorts(net));
      });
      return arr;
    },
    usedSubnets() {
      const usedPorts = this.$store.state.moduleStack.ports.filter(
        x => x.device_id === this.router
      );
      const sub = usedPorts.map(x => x.fixed_ips[0].subnet_id);
      return sub;
    },
    allocation_pools() {
      return this.id
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.id).allocation_pools
        : null;
    },
  },
  methods: {
    getAllPorts(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    getFreePorts(net) {
      return this.$store.state.moduleStack.ports
        .filter(x => !x.device_owner)
        .filter(x => x.network_id === net);
    },
    getAvailablePorts(subnet) {
      const range = this.$store.state.moduleStack.subnets.find(x => x.id === subnet)
        .allocation_pools[0];
      const all = this.getAllPorts(
        this.getLastIpSection(range.start),
        this.getLastIpSection(range.end)
      );
      const ports = this.$store.state.moduleStack.ports
        .map(x => x.fixed_ips)
        .flat()
        .filter(x => x.subnet_id === subnet)
        .map(x => x.ip_address)
        .map(x => this.getLastIpSection(x));
      const availablePorts = all.filter(x => !ports.includes(x)).length;
      return {
        id: this.$store.state.moduleStack.subnets.find(x => x.id === subnet).id,
        name: this.$store.state.moduleStack.subnets.find(x => x.id === subnet).cidr,
        ports: availablePorts,
      };
    },
    getLastIpSection(ip) {
      // console.log(ip);
      return +ip.split('.').at(-1);
    },
    subnetName(id) {
      const sub = this.availablePorts.find(x => x.id === id);
      const netName = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).name;
      const type = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).shared;
      const netId = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).id;
      const availableFreePorts = this.getFreePorts(netId).length;
      // console.log(availableFreePorts, netId);
      const name = sub.name;
      const port = sub.ports;
      // const string =
      //   name === netName
      //     ? `${netName} - ${type ? 'публичная' : 'приватная'} (доступно портов: ${
      //         availableFreePorts ? port + availableFreePorts : port
      //       })`
      //     : `${netName} - ${name} - ${type ? 'публичная' : 'приватная'} (доступно портов: ${
      //         availableFreePorts ? port + availableFreePorts : port
      //       })`;

      // return string;
      // console.log(netName);
      return type
        ? `${name} - ${type ? 'публичная подсеть' : 'приватная сеть'} (свободно портов: ${
            availableFreePorts ? port + availableFreePorts : port
          })`
        : `${netName} / ${name} - ${
            type ? 'публичная подсеть' : 'приватная сеть'
          } (свободно портов: ${availableFreePorts ? port + availableFreePorts : port})`;

      // return name === netName
      //   ? `${netName} - ${type ? 'публичная подсеть' : 'приватная сеть'} (свободно портов: ${
      //       availableFreePorts ? port + availableFreePorts : port
      //     })`
      //   : // : `${netName} - ${name} - ${type ? 'публичная' : 'приватная'} (свободно портов: ${
      //     `${name} - ${type ? 'публичная подсеть' : 'приватная сеть'} (свободно портов: ${
      //       availableFreePorts ? port + availableFreePorts : port
      //     })`;
    },
    portName(id) {
      // console.log('portName', id);
      return id === 'Новый порт'
        ? id
        : this.$store.state.moduleStack.ports.find(port => port.id === id).fixed_ips[0].ip_address;
      // return id;
    },
    simpleSubnetName(id) {
      const netName = this.$store.state.moduleStack.networks.find(x =>
        x.subnets.find(i => i === id)
      ).name;
      const subnetName = this.$store.state.moduleStack.subnets.find(x => x.id === id).cidr;
      return `${netName} - ${subnetName}`;
    },
    availableCreatedPorts(net) {
      const arr = [];
      const subnets = this.subnet;
      subnets.forEach(net => {
        arr.push(this.getAvailablePorts(net));
      });
      return arr.find(x => x.id === net).ports;
    },

    // getMask(ip) {
    //   return ip.slice(0, ip.lastIndexOf('.') + 1);
    // },
    // isCorrectIp(ip) {
    //   if (ip !== this.value) {
    //     if (this.getMask(ip) !== this.mask) {
    //       this.$emit('notready');
    //     } else if (this.pullIp.includes(this.getLastIpSection(ip))) {
    //       this.$emit('notready');
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //     } else if (isNaN(this.getLastIpSection(ip))) {
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //       this.$emit('notready');
    //     } else if (
    //       this.getLastIpSection(this.allocation_pools[0].end) < this.getLastIpSection(ip) ||
    //       this.getLastIpSection(this.allocation_pools[0].start) > this.getLastIpSection(ip)
    //     ) {
    //       this.formData.port.fixed_ips[0].ip_address = '';
    //       this.$emit('notready');
    //     } else {
    //       this.value = ip;
    //       this.formData.port.fixed_ips[0].ip_address = ip;
    //       this.$emit('ready', {
    //         port: this.formData.port,
    //       });
    //     }
    //   } else {
    //     this.formData.port.fixed_ips[0].ip_address = ip;
    //     this.$emit('ready', {
    //       port: this.formData.port,
    //     });
    //   }
    // },
    // getLastIpSection(ip) {
    //   return +ip.split('.').at(-1);
    // },
  },
};
