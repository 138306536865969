<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.storage') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <base-button class="stack-info__create-btn" @click="newStorage()">
            {{ $t('newStorage') }}
          </base-button>
        </div>
      </div>
      <tariffs-table-storage :storages="storages" :table-head="tableHead" />
    </page-block>
  </div>
</template>

<script>
import TariffsTableStorage from '../../Main/components/TariffsTableStorage';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import newStorage from '@/mixins/newStorage';
export default {
  name: 'ViewStorage',
  components: {
    TariffsTableStorage,
  },
  mixins: [newStorage],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'icon',
          style: {
            width: '20px',
            maxWidth: '20px',
            minWidth: '20px',
            textAlign: 'right',
            // maxWidth: '400px',
          },
        },
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '400px',
            maxWidth: '400px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        // {
        //   key: 'rule',
        //   label: this.$t('tableHead.rule'),
        //   style: {
        //     width: '130px',
        //     maxWidth: '130px',
        //   },
        // },

        {
          key: 'type',
          label: this.$t('tableHead.type'),
          style: {
            width: '100px',
          },
          // sort: {
          //   prop: 'operating_status',
          //   order: 'asc',
          // },
        },
        // {
        //   key: 'state',
        //   label: this.$t('tableHead.state'),
        //   style: {
        //     width: '48px',
        //   },
        //   sort: {
        //     prop: 'provisioning_status',
        //     order: 'asc',
        //   },
        // },
        {
          key: 'volume',
          label: this.$t('tableHead.volume'),
          style: {
            width: '60px',
          },
        },
        {
          label: this.$t('tableHead.items'),
          key: 'items',
          style: {
            width: '44px',
          },
        },
        {
          // label: this.$t('tableHead.menu'),
          key: 'delete',
          style: {
            width: '14px',
            textAlign: 'center',
          },
        },
      ],
      isRequest: true,
      empty: false,
      diskId: '',
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    storages() {
      return this.$store.getters['moduleStack/getStorages'];
      // return this.$store.state.moduleStack.storages;
    },
    headers() {
      return this.$store.state.moduleStack.storagesHeaders;
    },
    containersCount() {
      return this.storages && this.storages.length ? this.storages.length : null;
      // return this.headers['x-account-container-count'];
    },
  },
  watch: {
    // storages: {
    //   handler: function (event) {
    //     console.log(event);
    //     if (event) this.isRequest = false;
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.getOpenStackApiKey().then(() => {
      this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects').then(data => {
        const project = data && data.projects && data.projects[0] ? data.projects[0].id : null;
        if (project) this.fetchStorages(project).then();
      });
      // .finally(() => (this.isRequest = false));
    });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    // validateOpenStackApiKey() {
    //   return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    // },
    fetchStorages(payload) {
      return this.$store.dispatch('moduleStack/fetchProjectStorages', payload).then(data => {
        if (data && data.length) this.isRequest = false;
        else this.isRequest = false;
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "storage": "Контейнеры"
    },
    "new": "Добавить контейнер",
    "tableHead": {
      "name": "Имя",
      "type": "Тип",
      "items": "Объекты",
      "volume": "Объем"
    },
    "sure": {
      "confirm": "Создать"
    },
    "newStorage": "Добавить контейнер",
    "quotaNetwork": "Вы достигли квоты по количеству приватных сетей, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
